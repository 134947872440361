<template>
  <div
    :id="replyMsg ? 'q-with-reply' : ''"
    :class="'qchat-conv__' + message.type"
    style="white-space: normal;"
  >
    <div
      v-if="replyMsg"
      class="qchat-conv__replied q-pointer"
      :class="{ 'q-formatted-reply': replyMsg.type !== 'text' }"
      @click="goToConv(replyMsg.id)"
    >
      <div v-if="replyMsg.type === 'text'" class="q-with-padding">
        <p class="q-font-weight-bold q-text-primary q-mb-1 q-fs-14">
          {{ replyMsg.sender_id === user.id ? "You" : replyMsg.sender.name }}
        </p>
        <span class="qon-text--muted q-fs-12" style="color: black;">{{
          sliceWords(replyMsg.text, 52)
        }}</span>
      </div>
      <div
        v-else-if="replyMsg.type === 'image'"
        class="q-d-flex q-justify-content-between"
      >
        <div class="q-with-padding">
          <p class="q-font-weight-bold q-text-primary q-mb-1 q-fs-14">
            {{ replyMsg.sender_id === user.id ? "You" : replyMsg.sender.name }}
          </p>
          <span class="qon-text--muted q-fs-12" style="color: black;"
            ><Icons type="la-image" /> Image</span
          >
        </div>
        <div>
          <img :src="replyMsg.file.small.url" class="qchat-conv__reply-img" />
        </div>
      </div>
      <div
        v-else-if="replyMsg.type === 'video'"
        class="q-d-flex q-justify-content-between"
      >
        <div class="q-with-padding">
          <p class="q-font-weight-bold q-text-primary q-mb-1 q-fs-14">
            {{ replyMsg.sender_id === user.id ? "You" : replyMsg.sender.name }}
          </p>
          <span class="qon-text--muted q-fs-12" style="color: black;"
            ><Icons type="la-image" /> Video</span
          >
        </div>
        <div class="q-position-relative">
          <div class="qchat-conv__reply-mask q-d-flex centered-item">
            <Icons type="la-play" />
          </div>
          <video
            class="qchat-conv__reply-preview"
            preload="metadata"
            style="width: 60px; height: 55px;"
          >
            <source :src="replyMsg.file.url" />
          </video>
        </div>
      </div>
      <div
        v-else-if="replyMsg.type === 'document'"
        class="q-d-flex q-justify-content-between"
      >
        <div class="q-with-padding">
          <p class="q-font-weight-bold q-text-primary q-mb-1 q-fs-14">
            {{ replyMsg.sender_id === user.id ? "You" : replyMsg.sender.name }}
          </p>
          <span class="qon-text--muted q-fs-12" style="color: black;"
            ><Icons type="la-file-alt" /> Document</span
          >
        </div>
        <div class="qchat-conv__reply-doc">
          <Icons type="la-file-pdf" size="40" />
        </div>
      </div>
      <div
        v-else-if="replyMsg.type === 'location'"
        class="q-d-flex q-justify-content-between"
      >
        <div class="q-with-padding">
          <p class="q-font-weight-bold q-text-primary q-mb-1 q-fs-14">
            {{ replyMsg.sender_id === user.id ? "You" : replyMsg.sender.name }}
          </p>
          <span class="qon-text--muted q-fs-12" style="color: black;"
            ><Icons type="la-map-marker-alt" /> Location</span
          >
        </div>
        <div class="qchat-conv__reply-preview">
          <img
            :src="
              'https://maps.googleapis.com/maps/api/staticmap?center=' +
                replyMsg.extra.latitude +
                ',' +
                replyMsg.extra.longitude +
                '&zoom=13&size=62x55&markers=color:red%7C' +
                replyMsg.extra.latitude +
                ',' +
                replyMsg.extra.longitude +
                '&key=' +
                apiKey
            "
          />
        </div>
      </div>
    </div>
    <keep-alive>
      <component
        :is="'message-type-' + message.type"
        :message="message"
        :image-lists="imageLists"
        :list-mode="listMode"
        :channel="channel"
        @showLightBox="showLightBox"
      ></component>
    </keep-alive>
  </div>
</template>
<script>
const MessageTypeText = () => import("./MessageTypeText.vue");
const MessageTypeImage = () => import("./MessageTypeImage.vue");
const MessageTypeDocument = () => import("./MessageTypeDocument.vue");
const MessageTypeVideo = () => import("./MessageTypeVideo.vue");
const MessageTypeLocation = () => import("./MessageTypeLocation.vue");
const MessageTypeAudio = () => import("./MessageTypeAudio.vue");
const Icons = () => import("../../Icons/index.vue");
import { mapState } from "vuex";

export default {
  components: {
    MessageTypeText,
    MessageTypeImage,
    MessageTypeDocument,
    MessageTypeVideo,
    MessageTypeLocation,
    MessageTypeAudio,
    Icons
  },
  props: {
    message: {
      type: Object,
      default: () => {}
    },
    imageLists: {
      type: [Object, Array],
      default: () => {}
    },
    replyMsg: {
      type: [Object, Array],
      default: () => null
    },
    listMode: {
      type: String,
      default: ""
    },
    channel: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState("messenger", ["user"]),
    apiKey() {
      return process.env.GOOGLE_API_KEY;
    }
  },
  methods: {
    showLightBox(url) {
      this.$emit("showLightBox", url);
    },
    showLightBoxUrl(url) {
      this.$emit("showLightBox", url);
    },
    goToConv(convId) {
      const element = document.getElementById(convId);
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }
};
</script>
